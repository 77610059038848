function setupResults(){
	sectionResize();  

    function sectionResize() {

        function resize() {
            var windowHeight    = $(window).height(),
                mainHeight      = $('.dynamic-section').height(),
                bottomHeight    = $('.section-social-shares').height(),
                headerHeight    = $('.site-header').height(),
                footerHeight    = $('.site-footer').height();

            var heightToSet = windowHeight - headerHeight - footerHeight - bottomHeight;

            if (mainHeight <= heightToSet) {
                $('.dynamic-section').height(heightToSet);
            } else {
                $('.dynamic-section').height('auto');
            }
        }

        resize();

        $(window).resize(function () {
            resize();
        });

    }

    $('.change-email').click(function(){
        $('#changeemail-popup').addClass('show');
    });

    $('#changeemail-popup .overlay, #changeemail-popup .circle').click(function(){
        $('#changeemail-popup').removeClass('show');
    });

}